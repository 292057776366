import {createStore} from "vuex";

import picklistModule from "@/store/modules/picklist";
import demandModule from "@/store/modules/demandModule";
import simulationModule from "@/store/modules/simulation";
import thirdModule from "@/store/modules/thirdModule";
import searchThirdModule from "@/store/modules/searchThirdModule";
import authModule from "@/store/modules/authModule";
import taskModule from "@/store/modules/task/taskModule";
import financingModule from "@/store/modules/financing/financingModule";

import {RootState} from "@/types";
import {globalConfig} from "@/utils";
import middleOfficeModule from "@/store/modules/middle/middleOfficeModule";
import summaryPanelModule from "@/store/modules/task/actions/summaryPanelModule";
import notificationModule from "@/store/modules/communication/notification";
import messagingModule from "@/store/modules/communication/messaging";
import analysisModule from "@/store/modules/task/actions/analysisModule";
import scaleModule from "@/store/modules/configuration/ScaleModule";
import assetModule from "@/store/modules/configuration/AssetModule";
import garantyModule from "@/store/modules/configuration/GarantyModule";
import queryBuilderModule from "@/store/modules/queryBuilderModule";
import availabilityModule from "@/store/modules/configuration/availabilityModule";
import controlsModule from "@/store/modules/controls/controlsModule";
import dossierModule from "@/store/modules/dossier/DossierModule";
import commandModule from "@/store/modules/command/CommandModule";
import tabledata from "@/store/modules/tabledata";
import userModule from "@/store/modules/user/userModule";
import printDocumentModule from "@/store/modules/document/printDocumentModule";
import orderCommissionModule from "@/store/modules/order/commission/orderCommissionModule";
import orderModule from "@/store/modules/order/orderModule";
import screnConfigurationModule from "@/store/modules/configuration/ScrenConfigurationModule";
import filterModule from "./modules/filter/filterModule";
import orderPartyModule from "@/store/modules/order/party/orderPartyModule";
import roleMenuModule from "./modules/roleMenu/roleMenuModule";
import supersetModule from "./modules/supersetModule/supersetModule";
import dashboardModule from "./modules/dashboard/dashboardModule";

export default createStore<RootState>({
  getters: {},
  mutations: {

    updateSelectedComponent(state, payload) {

      const path = payload.path.split('.'); // Split the path string
      let currentState: any = state;
      // Traverse through the state object following the path
      for (let i = 0; i < path.length - 1; i++) {
        currentState = currentState[path[i]];
      }

      // Update the value at the end of the path
      currentState[path[path.length - 1]] = payload.value;

    },
    /**
      * Updates the value of the `currentContext` property in the Vuex state
      * @param {RootState} state - The Vuex state
      * @param {any} payload - The new value of the `currentContext` property
      */
    setCurrentContext(state, payload) {
      state.currentContext = payload;
    },
    setToRefresh(state, payload) {
      state.toRefresh = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLockState(state, payload) {
      state.lockState = payload;
    },
    setModule(state, payload) {
      state.module = payload;
    },
    setConfirmationDialogState(state, payload) {
      console.log(payload)
      state.confirmationDialogState = payload;
    },
    setCloseScale(state) {
      state.closeScale = !state.closeScale;
    },
    setConfirmationDeleteDialogState(state, payload) {
      state.confirmationDeleteDialogState = payload;
    },
    setCloseAvailability(state) {
      state.closeAvailability = !state.closeAvailability;
    },
  },
  actions: {
    updateSelectedComponent({ commit }, payload) {
      commit('updateSelectedComponent', payload)
    },
    /**
      * Updates the value of the `currentContext` property in the Vuex state
      * @param {Object} context - The context object of the Vuex store
      * @param {any} payload - The new value of the `currentContext` property
      */
    setCurrentContext({ commit }, payload) {
      commit("setCurrentContext", payload);
    },
    startLoading({ commit }) {
      commit("setLoading", true);
    },
    stopLoading({ commit }) {
      commit("setLoading", false);
    },
    module({ commit }, payload) {
      commit("setModule", payload);
    },
    lockState({ commit }) {
      commit("setLockState", true);
    },
    toRefresh({ commit }) {
      commit("setToRefresh", true);
    },
    NoRefresh({ commit }) {
      commit("setToRefresh", false);
    },
    releaseLockState({ commit }) {
      commit("setLockState", false);
      commit("setConfirmationDialogState", {
        show: false,
        confirmation: true
      });
      commit("setCloseScale")
      commit("setCloseAvailability")
      this.dispatch("garantyModule/showGarantyDetail")
      localStorage.removeItem(globalConfig.storageKeys.thirdModule);
      localStorage.removeItem(globalConfig.storageKeys.simulationModule);
      this.dispatch("demandModule/cleanState");
      this.dispatch("availabilityModule/cleanState");
      this.dispatch("dossierModule/hideDossierDetail");
      this.dispatch("dossierModule/setMode", "view");
      this.dispatch("middleOfficeModule/hideAnalysisReferenceDetails");

    },
    showConfirmationDialog({ commit }, param) {
      const { message, target, cancelButton, confirmButton, action, payload } = param;
      commit("setConfirmationDialogState", {
        message,
        cancelButton,
        confirmButton,
        target,
        show: true,
        action,
        payload
      });
    }
    ,
    hideConfirmationDialog({ commit }) {
      commit("setConfirmationDialogState", {
        show: false,
      });
    },
    hideConfirmationDeleteDialog({ commit }) {
      commit("setConfirmationDeleteDialogState", {
        show: false,
      });
    },
    executeDeleteConfirmationGaranty({ commit }, param) {
      commit("setConfirmationDialogState", {
        show: false,
        confirmation: true
      });
      this.dispatch("garantyModule/executeDeleteGaranty", { id: param.id, discriminator: param.discriminator, guarantyName: param.guarantyName });
    },
    executeDeleteConfirmationOrder({ commit }, param) {
      commit("setConfirmationDeleteDialogState", {
        show: false,
      });
      this.dispatch("orderModule/deleteOrder", {
        resourceUid:param.id,
      });
      commit("setToRefresh", true)
    },
    executeDeleteConfirmationEvent({ commit }, param) {
      commit("setConfirmationDeleteDialogState", {
        show: false,
        confirmation: true
      });
      this.dispatch('orderModule/setPlaningEvent',
        param.events
    )
    },
    executeDeleteConfirmationQueryFilter({ dispatch }, payload) {
      dispatch("filterModule/deleteSelectedFilter", payload);
    },
    executeDeleteConfirmationRemoveAlert({ commit }, payload) {
        commit("setConfirmationDeleteDialogState", {
          show: false,
        });
        this.dispatch("orderModule/deleteOrderAlert", {
          alertId:payload.id,
          orderId:payload.orderId
        });
        commit("setToRefresh", true)
    
    },
    executeDeleteConfirmationRemoveSubvention({ commit }, payload) {
      commit('orderModule/setorderSubvention', payload);
      this.dispatch("hideConfirmationDeleteDialog")
    },
    executeDeleteConfirmationNotification({ dispatch }, payload) {
      dispatch("notificationModule/updateStateOfMessage",payload);
      this.dispatch("hideConfirmationDeleteDialog")
    },
    showConfirmationDeleteDialog({ commit }, param) {

      const { message, target, payload, deleteAction } = param;
      commit("setConfirmationDeleteDialogState", {
        message,
        target,
        show: true,
        payload,
        deleteAction
      });
    },
    executeConfirmationDialog({ commit }, param) {
      const { payload , target } = param;
      commit("setConfirmationDialogState", {
        show: false,
        confirmation: true
      });
      if (payload.messageTosend){
      this.dispatch("taskModule/sendMessage", { messageTosend: payload.messageTosend, module:payload.module, target:target });
     }
    }

  },
  modules: {
    filterModule,
    authModule,
    demandModule,
    picklistModule,
    thirdModule,
    simulationModule,
    searchThirdModule,
    taskModule,
    financingModule,
    middleOfficeModule,
    summaryPanelModule,
    notificationModule,
    messagingModule,
    analysisModule,
    scaleModule,
    assetModule,
    garantyModule,
    queryBuilderModule,
    availabilityModule,
    controlsModule,
    userModule,
     //Efficom Specification
    tabledata,
    dossierModule,
    commandModule,
    orderModule,
    orderCommissionModule,
    printDocumentModule,
    screnConfigurationModule,
    orderPartyModule,
    roleMenuModule,
    supersetModule,
    dashboardModule
  },
});
