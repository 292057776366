import { supersetConfiguration } from "@/utils/configuration/superset-config";
import axios from "axios";
import { fetchSupersetConfiguration } from "./superset/supersetService";

/**
 * Retrieves the access token from the API.
 * @returns {Promise<string>} A promise that resolves to the access token.
 */
export const getAccesToken = async (config: any = null) => {
  let result: any = null;
  try {
    const dynamicConfig = config ?? await buildSupersetConfiguration();

    // Make a POST request to the login path with admin configuration
    result = await axios.post(
      `${dynamicConfig.base_url}${supersetConfiguration.loginPath}`,
      dynamicConfig.adminConfig
    );
  } catch (error) {
    return;
  }
  return result.data.access_token;
};

/**
 * Retrieves the CSRF token from the API.
 * @param {string} access_token - The access token required for authentication.
 * @returns {Promise<any>} A promise that resolves to the CSRF token response.
 */
export const get_csrf_token = async (access_token: any) => {
  let result: any = null;
  try {
    // Make a GET request to the CSRF path with authorization headers
    result = await axios.get(
      `${supersetConfiguration.base_url}${supersetConfiguration.csrfPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
        withCredentials: true,
      }
    );
  } catch (error) {
    return;
  }

  // setCookie('session', result.data.access_token)
  return result.data.result;
};

/**
 * Fetches the guest token from the API.
 * @returns {Promise<any>} A promise that resolves to the guest token response.
 */
export async function fetchGuestToken(): Promise<string> {
  try {
    const dynamicConfig = await buildSupersetConfiguration();
    const access_token = await getAccesToken(dynamicConfig);
    const csrf_token = await get_csrf_token(access_token);
    // Make a POST request to the guest token path with guest configuration and authentication headers
    const guestToken = await axios.post(
      `${dynamicConfig.base_url}${supersetConfiguration.guest_token}`,
      dynamicConfig.guestConfig,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          "X-CSRFToken": csrf_token,
        },
      }
    );
    return guestToken.data.token;
  } catch (error) {
    throw new Error("Error when generating the token");
  }
}

export async function buildSupersetConfiguration(): Promise<{
  base_url: string;
  adminConfig: {
    password: string;
    username: string;
    provider: string;
    refresh: string;
  };
  guestConfig: {
    user: {
      username: string;
      first_name: string;
      last_name: string;
    };
    resources: {
      type: string;
      id: string;
    }[];
    rls: any[];
  };
}> {
  const dynamicConfig = await fetchSupersetConfiguration();
  const basePath = dynamicConfig["SUPERSET_BASEPATH"];
  return {
    base_url: basePath ? `${basePath}/api/v1/` : supersetConfiguration.base_url,
    adminConfig: {
      password:
        dynamicConfig["SUPERSET_ADMINCONFIG_PASSWORD"] ??
        supersetConfiguration.adminConfig.password,
      username:
        dynamicConfig["SUPERSET_ADMINCONFIG_USERNAME"] ??
        supersetConfiguration.adminConfig.username,
      provider:
        dynamicConfig["SUPERSET_ADMINCONFIG_PROVIDER"] ??
        supersetConfiguration.adminConfig.provider,
      refresh:
        dynamicConfig["SUPERSET_ADMINCONFIG_REFRESH"] ??
        supersetConfiguration.adminConfig.refresh,
    },
    guestConfig: {
      user: {
        username:
          dynamicConfig["SUPERSET_GUESTCONFIG_USER_USERNAME"] ??
          supersetConfiguration.guestConfig.user.username,
        first_name:
          dynamicConfig["SUPERSET_GUESTCONFIG_USER_FIRSTNAME"] ??
          supersetConfiguration.guestConfig.user.first_name,
        last_name:
          dynamicConfig["SUPERSET_GUESTCONFIG_USER_LASTNAME"] ??
          supersetConfiguration.guestConfig.user.last_name,
      },
      resources: [
        {
          type:
            dynamicConfig["SUPERSET_GUESTCONFIG_RESOURCES_TYPE"] ??
            supersetConfiguration.guestConfig.resources[0]?.type,
          id:
            dynamicConfig["SUPERSET_GUESTCONFIG_RESOURCES_ID"] ??
            supersetConfiguration.guestConfig.resources[0]?.id,
        },
      ],
      rls: supersetConfiguration.guestConfig.rls,
    },
  };
}
