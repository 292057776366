import i18n from "@/i18n";
import { getTranslations, loadMessages } from "@/i18n.service.handler";
import {
  addGroupAuthority,
  addUserAuthority,
  deleteGroupAuthority,
  deleteUserAuthority,
  fetchGroupsAuthoritiesByCode,
  fetchRoleMenus,
  fetchSecurityGroups,
  fetchUserAuthoritiesByCode,
  saveNewRoleName,
  uploadTranslation,
} from "@/store/services/roleMenu/roleMenuService";
import { RoleMenuState } from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const roleMenuModule: Module<RoleMenuState, any> = {
  namespaced: true,
  state: {
    roleMenus: [],
    selectedRoleMenu: {},
    allUsers: [],
    allSecurityGroups: [],
    selectedUsers: [],
    selectedGroups: [],
    preExistingUsersIDs: [],
    preExistingGroupsIDs: [],
    preExistingUsers: [],
    preExistingGroups: [],
    newRoleName: "",
    newRoleEN: "",
    newRoleFR: "",
  },
  getters: {
    getRoleMenus(state) {
      return state.roleMenus;
    },
    getselectedRoleMenu(state) {
      return state.selectedRoleMenu;
    },
    getAllUsers(state) {
      return state.allUsers;
    },
    getAllSecurityGroups(state) {
      return state.allSecurityGroups;
    },
    getSelectedUsers(state) {
      return state.selectedUsers;
    },
    getSelectedGroups(state) {
      return state.selectedGroups;
    },
    getPreExistingUsersIDs(state) {
      return state.preExistingUsersIDs;
    },
    getPreExistingGroupsIDs(state) {
      return state.preExistingGroupsIDs;
    },
    getPreExistingUsers(state) {
      return state.preExistingUsers;
    },
    getPreExistingGroups(state) {
      return state.preExistingGroups;
    },
    getNewRoleName(state) {
      return state.newRoleName;
    },
    getNewRoleEn(state) {
      return state.newRoleEN;
    },
    getNewRoleFr(state) {
      return state.newRoleFR;
    },
  },
  actions: {
    async searchRoleMenus({ commit }) {
      const data = await fetchRoleMenus();
      commit("setRoleMenus", data);
      return data;
    },
    setselectedRoleMenu({ commit }, payload) {
      commit("setselectedRoleMenu", payload);
    },
    setAllUsers({ commit }, payload) {
      commit("setAllUsers", payload);
    },
    searchAllSecurityGroups({ commit }) {
      fetchSecurityGroups().then((data: any) =>
        commit("setAllSecurityGroups", data)
      );
    },
    async searchGroupsAuthoritiesByCode({ getters }) {
      const { id } = getters.getselectedRoleMenu;
      return await fetchGroupsAuthoritiesByCode(id);
    },
    addGroupAuthority({ commit }, payload) {
      const { authority_code, group_id } = payload;
      addGroupAuthority(authority_code, group_id);
    },
    deleteGroupAuthority({ commit }, payload) {
      deleteGroupAuthority(payload);
    },
    async searchUserAuthoritiesByCode({ getters }) {
      const { id } = getters.getselectedRoleMenu;
      return await fetchUserAuthoritiesByCode(id);
    },
    addUserAuthority({ commit }, payload) {
      const { authority_code, user_id } = payload;
      addUserAuthority(authority_code, user_id);
    },
    deleteUserAuthority({ commit }, payload) {
      deleteUserAuthority(payload);
    },
    setSelectedUsers({ commit }, payload) {
      commit("setSelectedUsers", payload);
    },
    setSelectedGroups({ commit }, payload) {
      commit("setSelectedGroups", payload);
    },
    setPreExistingUsersIDs({ commit }, payload) {
      commit("setPreExistingUsersIDs", payload);
    },
    setPreExistingGroupsIDs({ commit }, payload) {
      commit("setPreExistingGroupsIDs", payload);
    },
    setPreExistingUsers({ commit }, payload) {
      commit("setPreExistingUsers", payload);
    },
    setPreExistingGroups({ commit }, payload) {
      commit("setPreExistingGroups", payload);
    },
    saveNewAuthorities({ getters, dispatch }) {
      const {
        getPreExistingGroupsIDs,
        getPreExistingUsersIDs,
        getSelectedGroups,
        getSelectedUsers,
        getselectedRoleMenu,
        getPreExistingUsers,
        getPreExistingGroups,
        getNewRoleName,
      } = getters;
      if (getSelectedGroups) {
        getSelectedGroups
          .filter((s: string) => !getPreExistingGroupsIDs.includes(s))
          .forEach((item: string) => {
            dispatch("addGroupAuthority", {
              authority_code: getselectedRoleMenu.id,
              group_id: item,
            });
          });

        getPreExistingGroups
          .filter((l: any) => !getSelectedGroups.includes(l.group_id))
          .forEach((item: any) => {
            dispatch("deleteGroupAuthority", item.id);
          });
      }

      if (getSelectedUsers) {
        getSelectedUsers
          .filter((s: any) => !getPreExistingUsersIDs.includes(s.user_id))
          .forEach((item: any) => {
            dispatch("addUserAuthority", {
              authority_code: getselectedRoleMenu.id,
              user_id: item.user_id,
            });
          });

        const selectedUserIDs = getSelectedUsers.map((u: any) => u.user_id);
        getPreExistingUsers
          .filter((l: any) => !selectedUserIDs.includes(l.user_id))
          .forEach((item: any) => {
            dispatch("deleteUserAuthority", item.id);
          });
      }

      if (getNewRoleName) {
        dispatch("updateTranslation");
      }
    },
    setNewRoleName({ commit }, payload) {
      commit("setNewRoleName", payload);
    },
    resetNewRoleName({ commit }) {
      commit("setNewRoleName", "");
    },
    async saveNewRoleName({ getters }) {
      const { getNewRoleName, getNewRoleFr, getNewRoleEn } = getters;
      if (getNewRoleName && getNewRoleFr && getNewRoleEn) {
        const newRole = await saveNewRoleName(getNewRoleName);
        await uploadTranslation("en", newRole, getNewRoleEn);
        await uploadTranslation("fr", newRole, getNewRoleFr);
        return newRole;
      } else {
        Notify.create({
          message: i18n.global.t("menuRole.details.invalidFields"),
          color: "negative",
        });
      }

      return null;
    },
    async updateTranslation({ getters }) {
      const { getNewRoleName, getNewRoleFr, getNewRoleEn } = getters;
      if (getNewRoleName && getNewRoleFr && getNewRoleEn) {
        await uploadTranslation("en", getNewRoleName, getNewRoleEn);
        await uploadTranslation("fr", getNewRoleName, getNewRoleFr);
      }
    },
    resetSelections({ commit }) {
      commit("resetSelections");
    },
    async reloadTranslation({ commit }) {
      await getTranslations("en-US");
      await getTranslations("fr-FR");
    },
    setRoleNameTranslations({ commit }, payload) {
      const key = payload;
      const currentLocale = i18n.global.locale.value;

      i18n.global.locale.value = "fr-Us";
      const fr = i18n.global.t(`main.menu.${key}`);

      i18n.global.locale.value = "en-Us";
      const en = i18n.global.t(`main.menu.${key}`);

      commit("setNewRoleName", { key, en, fr });

      i18n.global.locale.value = currentLocale; // Return the current locale to its original value.
    },
  },
  mutations: {
    setRoleMenus(state, payload) {
      state.roleMenus = payload ?? [];
    },
    addRoleMenu(state, payload) {
      if (payload) state.roleMenus?.push(payload);
    },
    setselectedRoleMenu(state, payload) {
      state.selectedRoleMenu = payload ?? {};
    },
    setAllUsers(state, payload) {
      state.allUsers = payload ?? [];
    },
    setAllSecurityGroups(state, payload) {
      state.allSecurityGroups = payload ?? [];
    },
    setSelectedUsers(state, payload) {
      state.selectedUsers = payload ?? [];
    },
    setSelectedGroups(state, payload) {
      state.selectedGroups = payload ?? [];
    },
    setPreExistingUsersIDs(state, payload) {
      state.preExistingUsersIDs = payload ?? [];
    },
    setPreExistingGroupsIDs(state, payload) {
      state.preExistingGroupsIDs = payload ?? [];
    },
    setPreExistingUsers(state, payload) {
      state.preExistingUsers = payload ?? [];
    },
    setPreExistingGroups(state, payload) {
      state.preExistingGroups = payload ?? [];
    },
    setNewRoleName(state, payload) {
      const { key, en, fr } = payload;
      state.newRoleName = key ?? "";
      state.newRoleEN = en ?? "";
      state.newRoleFR = fr ?? "";
    },
    resetSelections(state) {
      state.selectedRoleMenu = {};
      state.selectedUsers = [];
      state.selectedGroups = [];
      state.preExistingUsersIDs = [];
      state.preExistingGroupsIDs = [];
      state.preExistingUsers = [];
      state.preExistingGroups = [];
      state.newRoleName = "";
      state.newRoleEN = "";
      state.newRoleFR = "";
    },
    setNewRoleEN(state, payload) {
      state.newRoleEN = payload ?? "";
    },
    setNewRoleFR(state, payload) {
      state.newRoleFR = payload ?? "";
    },
  },
};
export default roleMenuModule;
