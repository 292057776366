import { api } from "@/auth/api";
import { settings } from "@/settings";
import { filter } from "@/types";
import { globalConfig, headers,operator, setOtherFilters } from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import axios from "axios";
import { getInOperator } from "../picklist";
import {getTaskCountByUser} from "@/store/services/task/workflow";

export async function getSavedFilters(queryId: any) {

    const listNotFiltered = await listOfFilters(queryId)
    listNotFiltered.sort((a: filter, b: filter) => a.numOrder - b.numOrder);
    for (const filter of listNotFiltered) {
        if (filter.businessData && filter.businessData.queryId) {
          const data: any = await searchCount(filter)
          filter.count = data.count
        } else if(filter.screenId === 'search-task'){

            const filterCamunda: any = {...filter}

            delete filterCamunda.screenId
            delete filterCamunda.description
            delete filterCamunda.color
            delete filterCamunda.hasPublicAccess
            delete filterCamunda.numOrder

            filterCamunda.initializeVariables = filter.businessData.initializeVariables
            filterCamunda.candidateGroups = filter.businessData.candidateGroups
            filterCamunda.searchVariables = filter.businessData.searchVariables
            filterCamunda.taskDefinitionKeyIn = filter.businessData.taskDefinitionKeyIn
            filterCamunda.includeAssignedTasks = filter.businessData.includeAssignedTasks

            const data: any = await getTaskCountByUser(filterCamunda)
            filter.count = data.data.count
        } else {
          filter.count = 0
        }
    }
    return listNotFiltered
}

async function listOfFilters(queryId: any) {
  const systemUid = "odm-configuration";
  return (
    await api().get(
      `/${systemUid}/api/1/${systemUid}/filterstore/screenid/${queryId}/`
    )
  ).data;
}

export const saveFilter = async (payload: any) => {
  const systemUid = "odm-configuration";
  delete payload.count;
  return (
    await api().post(`/${systemUid}/api/1/${systemUid}/filterstore/`, payload)
  ).data;
};


export const updateFilter = async (payload: any) => {
  const systemUid = 'odm-configuration'
  delete payload.count
  return (await api().put(`/${systemUid}/api/1/${systemUid}/filterstore/` + payload.resourceUid + '/', payload)).data
}

export function getOperator(
  table: string,
  field: string,
  operator: string,
  value?: any,
  parameter?: string,
  inParameters?: string[]
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
      inValue:
        inParameters && inParameters.length > 0
          ? {
              values: inParameters,
            }
          : null,
    },
  };
}

export function getQcUser(filter: any , offset?: any, limit?: any) {

  const operators = [];

  const qc_header: any = {
    qc: {
      queryId: "user-lease-forge-user",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };

  const LEASE_FORGE_USER = "user_lease_forge_user";

  if (filter?.username) {
    operators.push(
      operator({
        val: filter.username,
        table: LEASE_FORGE_USER,
        dbField: "username",
      }).LIKE
    );
  }

  if (filter?.firstName) {
    operators.push(
      operator({
        val: filter.firstName,
        table: LEASE_FORGE_USER,
        dbField: "first_name",
      }).LIKE
    );
  }

  if (filter?.lastName) {
    operators.push(
      operator({
        val: filter.lastName,
        table: LEASE_FORGE_USER,
        dbField: "last_name",
      }).LIKE
    );
  }


  if (filter?.email) {
    operators.push(
      operator({
        val: filter.email,
        table: LEASE_FORGE_USER,
        dbField: "email",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: LEASE_FORGE_USER,
        dbField: "enabled",
      }).EQUAL
    );
  }

  if (filter?.notExpired) {
    operators.push(
      operator({
        val: filter.notExpired,
        table: LEASE_FORGE_USER,
        dbField: "account_not_expired",
      }).EQUAL
    );
  }

  if (filter?.notLocked) {
    operators.push(
      operator({
        val: filter.notLocked,
        table: LEASE_FORGE_USER,
        dbField: "account_not_locked",
      }).EQUAL
    );
  }

  if (filter?.credintialsNotExpired) {
    operators.push(
      operator({
        val: filter.credintialsNotExpired,
        table: LEASE_FORGE_USER,
        dbField: "credentials_not_expired",
      }).EQUAL
    );
  }

  setOtherFilters(operators, qc_header);

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
  return qc_header.qc;

}


export function getQcGaranty(filter : any , offset? : any , limit? : any) {
  const operators = [];

  const qc_header: any = {
    qc: {
      queryId: "product-product-feature",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };

  const PRODUCT_FEATURE = "product_product_feature";

  if (filter?.reference) {
    operators.push(
      operator({
        val: filter.reference,
        table: PRODUCT_FEATURE,
        dbField: "reference",
      }).LIKE
    );
  }


  if (filter?.type) {

    operators.push(
      operator({
        val: filter.type,
        table: PRODUCT_FEATURE,
        dbField: "_discriminator",
      }).LIKE
    );
  }

  if (filter?.guaranty_name) {
    operators.push(
      operator({
        val: filter.guaranty_name,
        table: PRODUCT_FEATURE,
        dbField: "name",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: PRODUCT_FEATURE,
        dbField: "status_code",
      }).LIKE
    );
  }

  setOtherFilters(operators, qc_header);

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators
          }
        }
      ]
    }
  }
  return qc_header.qc;

}

export function getQcGood(filter: any , offset?: any , limit?: any) {
    const qc_header: any = {
      qc: {
        queryId: 'asset-search-filter',
        offset: offset || 0,
        limit: limit || 100
      }
    }

    qc_header.qc.parameters = {
      ...filter
    }
    const operators = []


    if (filter.reference) {
      operators.push(getOperator("lower(product_product_feature", "reference)", "LIKE", globalConfig.formatters.getEqualValue(filter.reference + '%').toLowerCase(), "reference"))
    }
    if (filter.name) {
      operators.push(getOperator("lower(product_product_feature", "name)", "LIKE", globalConfig.formatters.getEqualValue(filter.name + '%').toLowerCase(), "name"))
    }
    if (filter.brand) {
      operators.push(getOperator("lower(ppc_brand", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.brand.label + '%').toLowerCase(), "id"))
    }
    if (filter.model) {
      operators.push(getOperator("lower(ppc_model", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.model.label + '%').toLowerCase(), "id"))
    }
    if (filter.status) {
      operators.push(getOperator("product_product_feature", "status_code", "EQUAL", globalConfig.formatters.getEqualValue(filter.status), "status"))
    }
    if (filter.family) {
      operators.push(getOperator("lower(ppc_famille", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.family.id + '%').toLowerCase(), "id"))
    }
    if (filter.catalog) {
      operators.push(getOperator("lower(product_product_catalog", "reference)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.catalog), "reference"))
    }
    if (filter.supplier && filter.supplier.id) {
      operators.push(getOperator("product_product_catalog", "provider_resourceuid", "LIKE", globalConfig.formatters.getEqualValue(filter.supplier.id.resourceUid), "provider_resourceuid"))
    }
    if (filter.inventory) {
      operators.push(getOperator("product_inventory_item", "asset_product_id", "ISNOTNULL"));
      }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
    }
    return qc_header.qc;

}

export function getQcScale(filter: any, offset?: any , limit?: any) {
  const operators = [];

    const qc_header: any = {
        qc: {
            queryId: 'scales-search-filter',
            offset: offset || 0,
            limit: limit || 100,
            parameters: {
                ...filter
            }
        }
    };

    const PRODUCT_FEATURE = "product_product_feature";
    const PRODUCT_CLASSIFICATION = "product_product_classification";

    if (filter?.name) {
        operators.push(
            operator(
                { val: filter.name, table: PRODUCT_FEATURE, dbField: "name" }
            ).LIKE
        );
    }

    if (filter?.reference) {
        operators.push(
            operator(
                { val: filter.reference, table: PRODUCT_FEATURE, dbField: "reference" }
            ).LIKE
        );
    }

    if (filter?.validity?.from && filter?.validity?.from !== '') {
        operators.push(
            operator(
                { val: filter.validity.from, table: PRODUCT_FEATURE, dbField: "validity_from" }
            ).GREATER_THAN_EQUAL
        );
    }

    if (filter?.validity?.until && filter?.validity?.until !== '') {
        operators.push(
            operator(
                { val: filter.validity.until, table: PRODUCT_FEATURE, dbField: "validity_until" }
            ).LESS_THAN_EQUAL
        );
    }

    if (filter?.financialProfile) {
        operators.push(
            operator(
                { val: filter.financialProfile, table: PRODUCT_FEATURE, dbField: "financial_profile_code" }
            ).EQUAL
        );
    }

    if (filter?.status) {
        operators.push(
            operator(
                { val: filter.status, table: PRODUCT_FEATURE, dbField: "status_code" }
            ).EQUAL);
    }

    /**
     * Checks if the array contains any value that is not undefined.
     *
     * @template T
     * @param {T[]} arr - The array to check.
     * @return {boolean} True if the array contains any non-undefined value, false otherwise.
     */
    const notOnlyUndefined = <T>(arr: T[]): boolean => arr.some(item => item !== undefined);

    /**
     * Removes any undefined values from an array.
     *
     * @template T
     * @param {T[]} arr - The array to remove undefined values from.
     * @return {T[]} The array without any undefined values.
     */
    const removeTheUndefined = <T>(arr: T[]): T[] => arr.filter(item => item !== undefined);

    if (filter?.category && filter.category.length > 0 && notOnlyUndefined(filter.category)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.category),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_code"
                }
            ).IN
        );
    }

    if (filter?.class && filter.class.length > 0 && notOnlyUndefined(filter.class)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.class),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_class_code"
                }
            ).IN
        );
    }

    setOtherFilters(operators, qc_header);

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    return qc_header.qc;
}

export function getQcAvailability(filter: any, offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: 'product-availability-filter-search',
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter
      }
    }
  };

  const operators = [];
  const PRODUCT_AVAILABILITY = "product_product_availability";

  if (filter?.name) {
    operators.push(
      operator(
        { val: filter.name, table: PRODUCT_AVAILABILITY, dbField: "application_name" }
      ).LIKE
    );
  }

  if (filter?.validity?.from && filter?.validity?.from !== '') {
    operators.push(
      operator(
        { val: filter.validity.from, table: PRODUCT_AVAILABILITY, dbField: "validity_from" }
      ).GREATER_THAN_EQUAL
    );
  }

  if (filter?.validity?.until && filter?.validity?.until !== '') {
    operators.push(
      operator(
        { val: filter.validity.until, table: PRODUCT_AVAILABILITY, dbField: "validity_until" }
      ).LESS_THAN_EQUAL
    );
  }

  if (filter?.context) {
    operators.push(
      operator(
        { val: filter.context, table: PRODUCT_AVAILABILITY, dbField: "context" }
      ).LIKE
    );
  }

  // if (filter?.context !== undefined) {
  //     operators.push(getOperator(PRODUCT_AVAILABILITY, "context", "EQUAL", globalConfig.formatters.getEqualValue(filter.context ), "context"))
  // }

  setOtherFilters(operators, qc_header);
  
  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;

}

export function getQcOrder(filter: any,offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "order-search-filter",
      offset: offset || 0,
      limit: limit || 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];

  if (filter.filterReference) {
    operators.push(
      getOperator(
        "lower(order_order",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.filterReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.externalReference) {
    operators.push(
      getOperator(
        "lower(order_order_item",
        "external_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.externalReference + "%")
          .toLowerCase(),
        "external_reference"
      )
    );
  }
  if (filter.filterStatus) {
    operators.push(
      getOperator(
        "order_order",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.filterStatus),
        "status_code"
      )
    );
  }
  if (filter.manufacturingNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "serial_number)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.manufacturingNumber + "%")
          .toLowerCase(),
        "serial_number"
      )
    );
  }
  if (filter.registrationNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.registrationNumber + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  if (filter.activity !== undefined) {
    operators.push(
      getOperator(
        "order_order_asset",
        "flag_new",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.activity),
        "flag_new"
      )
    );
  }
  if (filter.delivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNOTNULL")
    );
  }
  if (filter.notDelivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNULL")
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "order_order_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand),
        "brand"
      )
    );
  }
  if (filter.chassisReference) {
    operators.push(
      getOperator(
        "order_order_asset",
        "chassis_reference",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.chassisReference),
        "chassis_reference"
      )
    );
  }
  if (filter.contractStatus) {
    operators.push(
      getOperator(
        "order_order_item",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.contractStatus),
        "status_code"
      )
    );
  }

  if (filter.deliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order",
        "effective_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateFrom, "DD/MM/YYYY", "fr").toString,
        "deliveryDateFrom"
      )
    );
  }
  if (filter.deliveryDateTo) {
    operators.push(
      getOperator(
        "order_order",
        "effective_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateTo, "DD/MM/YYYY", "fr").toString,
        "deliveryDateTo"
      )
    );
  }
  if (filter.previsonalDeliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order",
        "prevision_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateFrom, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateFrom"
      )
    );
  }
  if (filter.previsonalDeliveryDateTo) {
    operators.push(
      getOperator(
        "order_order",
        "prevision_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateTo, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateTo"
      )
    );
  }
  if (filter.supplierName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.supplierName + "%")
          .toLowerCase(),
        "name"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.clientReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }

  if (filter.role_code && filter.role_code.length > 0) {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.role_code),
            "role_code"
        )
    );
  } else {
    operators.push(
        getOperator(
            "order_order_party_1",
            "role_code",
            "EQUAL",
            "'CUSTOMER'",
            "role_code"
        )
    );
  }


  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export function searchCount(filter: any): Promise<any[]> {
  return new Promise<any[]>((resolve, reject) => {
    const url = settings.api_query_count_url;
    const _headers = headers({
      qc: JSON.stringify(filter.businessData),
    });
    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export function getQcOffer(filter: any,offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "offer-filter-search",
      offset: offset || 0,
      limit: limit || 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];

  if (filter.demandStatus) {
    operators.push(
      getOperator(
        "offer_offer_status",
        "id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.demandStatus),
        "id"
      )
    );
  }
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(offer_offer",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.reference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.firstName + "%")
          .toLowerCase(),
        "firstName"
      )
    );
  }
  if (filter.familyName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.familyName + "%")
          .toLowerCase(),
        "familyName"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.clientReference + "%")
          .toLowerCase(),
        "clientReference"
      )
    );
  }
  if (filter.from) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.from, "DD/MM/YYYY", "fr").toString,
        "from"
      )
    );
  }
  if (filter.until) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.until, "DD/MM/YYYY", "fr").toString,
        "until"
      )
    );
  }
  if (filter.channel) {
    operators.push(
      getOperator(
        "offer_offer",
        "sales_channel_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.channel.id),
        "channel"
      )
    );
  }
  if (filter.typeClient) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.typeClient.id),
        "typeClient"
      )
    );
  }
  if (filter.network) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_networks_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.network.id),
        "network"
      )
    );
  }
  if (filter.networkNode) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_network_nodes_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.networkNode.id),
        "networkNode"
      )
    );
  }
  if (filter._decisionAnalysisProcessId) {
    operators.push(
      getOperator(
        "offer_offer",
        "_decisionAnalysisProcessId",
        "EQUAL",
        globalConfig.formatters.getEqualValue(
          filter._decisionAnalysisProcessId.id
        ),
        "_decisionAnalysisProcessId"
      )
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand.id),
        "brand"
      )
    );
  }
  if (filter.range) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "range",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.range.id),
        "range"
      )
    );
  }
  if (filter.immatriculation) {
    operators.push(
      getOperator(
        "lower(offer_proposal_asset",
        "matriculation)",
        "EQUAL",
        globalConfig.formatters
          .getEqualValue(filter.immatriculation)
          .toLowerCase(),
        "immatriculation"
      )
    );
  }
  if (filter.commercialName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.commercialName + "%")
          .toLowerCase(),
        "commercialName"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getEqualValue(filter.siren + "%").toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.role && filter.role.length > 0) {
    operators.push(
      getInOperator(
        "party_party",
        "id",
        "IN",
        null,
        null,
        globalConfig.formatters.getInValue(filter.role)
      )
    );
  } else {
    operators.push(
      getOperator(
        "offer_associated_party",
        "role_code",
        "EQUAL",
        "'CUSTOMER'",
        "role_code"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export function getQcParty(filter: any, offset?: any, limit?: any) {
  const qc_header: any = {
    qc: {
      queryId: "party-filter-search",
      offset: offset || 0,
      limit: limit || 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "reference)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.reference).toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siren).toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.family_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
          `'${filter.family_name.toLowerCase()}'`,
        //CF-379
        //globalConfig.formatters.getLikeValue(filter.family_name).toLowerCase(),
        "family_name"
      )
    );
  }

  if (filter.birthday) {
    operators.push(
      getOperator(
        "party_party",
        "birth_date",
        "EQUAL",
        formatDateLocale(filter.birthday, "DD/MM/YYYY", "fr").toString,
        "birthday"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        //CF-379
        `'${filter.firstName.toLowerCase()}'`,
        //globalConfig.formatters.getLikeValue(filter.firstName.toLowerCase()),
        "firstName"
      )
    );
  }
  if (filter.discriminator) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.discriminator === "party-FrenchPerson"
            ? "Person"
            : "Organization"
        ),
        "discriminator"
      )
    );
  }
  if (filter.city) {
    operators.push(
      getOperator(
        "party_postal_code",
        "city",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.city),
        "city"
      )
    );
  }
  if (filter.postalCode) {
    operators.push(
      getOperator(
        "party_postal_code",
        "postal_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.postalCode),
        "postalCode"
      )
    );
  }
  if (filter.siret) {
    operators.push(
      getOperator(
        "lower(party_facility",
        "siret)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siret).toLowerCase(),
        "siret"
      )
    );
  }
  if (filter.phoneNumber) {
    operators.push(
      getOperator(
        "party_contact_mechanism",
        "phone_number",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.phoneNumber),
        "phoneNumber"
      )
    );
  }
  if (filter.relationTypeCode) {
    operators.push(
      getOperator(
        "party_party_relation",
        "relation_type_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.relationTypeCode),
        "relationTypeCode"
      )
    );
  }
  if (filter.role) {
    operators.push(
      getOperator(
        "party_party_role",
        "role_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.role.value),
        "role_code"
      )
    );
  }
  if (filter.commercial_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.commercial_name.toLowerCase()
        ),
        "commercial_name"
      )
    );
  }
  if (filter.current_status_code) {
    operators.push(
      getOperator(
        "party_party",
        "current_status_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.current_status_code.id),
        "current_status_code"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export async function deleteFilter(filter: any) {
  const { resourceUid, systemUid } = filter;
  const url = `/${systemUid}/api/1/${systemUid}/filterstore/${resourceUid}/`;
  return (await api().delete(url));
}

export function isFilterDAAQMatching(filterDaaq: string, connectedUserDaaq: { readDAAQ: string, defaultCreateDAAQ: string }){
  const { readDAAQ, defaultCreateDAAQ } = connectedUserDaaq;
  if (readDAAQ === "/" || defaultCreateDAAQ === "/") return true; // In case user super admin or filter public.
  const segments = filterDaaq.split("/").filter((segment: string) => segment);
  const patterns = segments.map((_: any, index: number) => {
    const subPath = segments.slice(0, index + 1).join("/");
    return new RegExp(`^/${subPath}/$`);
  });
  return patterns.some((pattern: RegExp) => pattern.test(readDAAQ) || pattern.test(defaultCreateDAAQ));
}