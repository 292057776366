import {Module} from "vuex";
import {NoticeTemplate, PackNoticeTemplateInfo, PrintDocumentState} from "@/types";
import {
    checkDocumentGenerated,
    getMail,
    getNoticeBody,
    getTemplates,
    handleSendPrintRequest,
    sendMailWithAllDocument,
    sendPrintRequest
} from "@/store/services/task/actions/printDocumentService";
import {deepClone, deleteAttributesRecursively} from "@/utils";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import {downloadFile, initEsign} from "@/store/services/offerService";

interface Options {
    error: any,
    action: string
}
const errorTreatment = (options: Options) => {
    const {
        error,
        action = ''
    } = options;
    console.error(`${action} action => ${error.response?.data?.title ?? ''} \n ${error}`)
}

function fillRequestEntity(currentRoute: string) {
    let entity: any = ''
    let entityType  = ''
    let entityId = ''
    let roleCode = ''
    switch (currentRoute) {
        case "order-edit": {
            entityType = 'order'
            entity = store.state.orderModule.order
            entityId = entity.resourceUid
            roleCode = entity.role_code ? entity.role_code : 'CUSTOMER'
            break;
        }

        case "TaskDetail": {
            entityType = store.getters['taskModule/isTaskModuleOffer'] ? 'offer' : 'order'
            if (entityType === 'offer') {
                entity = store.state.taskModule.offer
            } else {
                entity = store.state.taskModule.order
            }
            entityId = entity.resourceUid
            roleCode = entity.role_code ? entity.role_code : 'CUSTOMER'
            break;
        }

        case "demand-edit": {
            entityType = 'offer'
            entity = store.state.demandModule.offer
            entityId = entity.resourceUid
            roleCode = entity.role_code ? entity.role_code : 'CUSTOMER'
            break;
        }

        case "third-edit": {
            entityType = 'party'
            entity = store.state.thirdModule.third
            entityId = entity.id.resourceUid
            roleCode = entity.role_code ? entity.role_code : 'CUSTOMER'
            break;
        }
    }
    return {entityType, entityId, roleCode, entity};
}

async function waitSendPrintResponse(request: {
    entityType: string;
    roleCode: string;
    entityId: string;
    entity: any
}, index: any, response: any, commit: any, callback: any) {
    let attempts = 0;
    const maxAttempts = 12;
    const intervalId = setInterval(async () => {
        try {
            const fileDetail = await checkDocumentGenerated(request.entityId, index, request.entityType, response.processId);
            if (fileDetail && fileDetail.fileId) {
                commit('setFileDetailFileId', fileDetail.fileId);
                callback(callback);
                clearInterval(intervalId);
            } else {
                attempts++;
                if (attempts >= maxAttempts) {
                    clearInterval(intervalId);
                    Notify.create({
                        message: 'It was not possible to generate the document',
                        color: 'negative'
                    })
                }
            }
        } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
                clearInterval(intervalId);
                Notify.create({
                    message: 'It was not possible to generate the document',
                    color: 'negative'
                })
            }
            console.error('Document not generated yet for entityId');
        }
    }, 3000);
}

const printDocumentModule: Module<PrintDocumentState, any> = {
    namespaced: true,
    state: {
        show: false,
        showButton: false,
        templates: [],
        emailSendList: [],
        body: '',
        showButtonsPreview: false,
        showButtonValidate: false,
        fileDetailFileId: '',
        fileContent:''
    },
    getters: {
        getEmailSendListOption(state){
            return state.emailSendListOption;
        },
        getFileDetailFileId(state) {
            return state.fileDetailFileId;
        },
        getFileContent(state) {
            return state.fileContent;
        }
    },
    actions: {
        async initState({ dispatch }, payload) {
            if (!payload || !payload.reference) {
                throw new Error('onSuccess and onFail are mandatory')
            }
            // TODO EF-202 finish
            // const result = await buildPartyControls(this.state)
            const result: any = []
            if (!result) {
                throw new Error('Internal Error')
            }
            payload.data = result.data
            payload.entity = result.entity
            dispatch('setReference', payload.reference)
            dispatch('evaluateControls', payload)
        },
        async  generateDocument({commit }, payload) {
            const { index, templatesSelected, currentRoute, callback } = payload
            const request = fillRequestEntity(currentRoute);
            //TODO - For now we are always generating  a new report, I found a problem with this logic so we need to check
            const checkGeneratedDoc : any = ''
/*
            try {
                if(index != null && !store.state.printDocumentModule.templates[index].isPack){
                    checkGeneratedDoc = await checkDocumentGenerated(request.entityId, store.state.printDocumentModule.templates[index].resourceUid, request.entityType);
                }
            }catch (error) {
                console.error('Document not generated yet for entityId: ', request.entityId);
            }
*/

            if (checkGeneratedDoc && checkGeneratedDoc.fileId) {
                commit('setFileDetailFileId', checkGeneratedDoc.fileId);
                callback(callback);
            }else{

                let response: any = ''
                if(index != null && store.state.printDocumentModule.templates[index].isPack){
                    response = await sendPrintRequest(handleSendPrintRequest(request.entityType, store.state.printDocumentModule.templates[index], request.entityId, request.roleCode), request.entityType, store.state.printDocumentModule.templates[index].resourceUid)
                }else if(index != null){
                    response = await sendPrintRequest(handleSendPrintRequest(request.entityType, store.state.printDocumentModule.templates[index], request.entityId, request.roleCode), request.entityType)
                } else if(templatesSelected.length > 0){
                    response = await sendPrintRequest(handleSendPrintRequest(request.entityType, templatesSelected, request.entityId, request.roleCode), request.entityType)
                }

                if (response) {
                    await waitSendPrintResponse(request, index, response, commit, callback);
                } else{
                    Notify.create({
                        message: 'It was not possible to generate the document',
                        color: 'negative'
                    })
                    callback(callback);
                }
            }
        },
        toggleShow({ commit }) {
            commit('toggleShow')
        },
        toggleShowButton({ commit }) {
            commit('toggleShowButton')
        },
        getTemplates({commit}, payload) {
            const {currentRoute} = payload
            getTemplates(currentRoute)
                .then(templates => commit('setTemplates', templates))
                .catch(err => {
                    console.error(err)
                    Notify.create({
                        message: err.message || err.error || err,
                        color: 'negative'
                    })
                })
        },
        async sendDocument({commit}, payload) {
            const {emailSendList, callback, fileId} = payload
            if(fileId){
                sendMailWithAllDocument(emailSendList, fileId)
                    .then(result => {
                        commit('checkDownload', true)
                        commit('setShowSendTemplate', false)
                        if (callback) {
                            callback()
                        }
                        Notify.create({
                            message: i18n.global.t('task.printDocument.message'),
                            color: 'positive'
                        });
                    })
                    .catch(err => {
                        console.error(err)
                        Notify.create({
                            message: err.message || err.error || err,
                            color: 'negative'
                        })
                    })
            }else{
                Notify.create({
                    message: 'It was not possible to generate the document to send by email. Please, try again.',
                    color: 'negative'
                })
            }

        },
        setPreviewTemplate({commit, dispatch}, payload) {
            if(store.state.printDocumentModule.fileDetailFileId){
                const {template, callback} = payload
                if ( !template ) {
                    throw new Error('Template is mandatory')
                }

                commit('setVisualizedTemplate', template)
                downloadFile(store.state.printDocumentModule.fileDetailFileId).then(data => {
                    commit('setFileContent', data.content);
                    const contentPDF = atob(data.content)
                    const arrPDF = new Uint8Array(contentPDF.length);
                    for ( let i = 0; i < contentPDF.length; i++ ) {
                        arrPDF[i] = contentPDF.charCodeAt(i);
                    }
                    commit('setPreviewTemplate', arrPDF)
                    if ( callback ) {
                        callback()
                    }
                }).catch(err => {
                    console.error(err)
                    Notify.create({
                        message: err.message || err.error || err,
                        color: 'negative'
                    })
                })
            }

        },
        initEsign: ({ commit }, payload) => {
            const { request, callback,message } = payload
            initEsign(request).then(response => {
                if (callback) {
                    callback(response, message)
                }
            }).catch(err => {
                console.error(err)
            })
        },
        downloadFile: ({ commit }, payload) => {
            const { message,callback } = payload
            //for test Only
            // const tempTestFile = "6664d2325e6170006815c984"
            downloadFile(store.state.printDocumentModule.fileDetailFileId).then(response => {
                commit('setFileContent', response.content);
                if (callback) {
                    callback(response, message)
                }
            }).catch(e => errorTreatment({ error: e, action: 'downloadFile' }))
        },
        async getEmailSendListOption({commit}, payload) {
            const {entity, entityType} = payload
            await getMail(entity, entityType).then(response => {
                if(response.items.length > 0){
                    commit('setEmailSendListOption', response)
                }else{
                    Notify.create({
                        message: 'No emails found.',
                        color: 'negative'
                    })
                }
            }).catch(err => {
                console.error(err)
                Notify.create({
                    message: err.message || err.error || err,
                    color: 'negative'
                })
            })
        },
        getNoticeTemplateBody({ commit }){
            getNoticeBody().then(response=>{
                commit('setTemplateBody', response)

            }).catch(err => {
                console.error(err)})
        },

        sendTemplate({commit, dispatch}, payload) {
            const {template} = payload
            if (!template) {
                throw new Error('Template is mandatory')
            }
            if(!template.isPack || (template.isPack && !template.selected)){
                dispatch('setTemplateSelected', {template, selected: true})
            }
            commit('setShowSendTemplate', true)
        },
        setAllTemplateSelected({dispatch}, payload) {
            const {selected} = payload
            const {templates} = this.state.printDocumentModule
            templates.forEach((template: any) => dispatch('setTemplateSelected', {template, selected}))
            dispatch('setSelectedTemplates')
        },
        setTemplateSelected({commit, dispatch}, payload) {
            const {template} = payload
            if (!template) {
                throw new Error('Template is mandatory')
            }
            commit('setTemplateSelected', payload)
            dispatch('setSelectedTemplates')
        },
        setTemplateSelectedItem({commit, dispatch}, payload) {
            const {template} = payload
            if (!template) {
                throw new Error('Template is mandatory')
            }
            commit('setTemplateSelectedItem', payload)
            dispatch('setSelectedTemplates')
        },
        changeShowSendTemplate({commit}, payload) {
            commit('setShowSendTemplate', payload)
        },
        addEmailSenderList({commit}, payload) {
            const {email} = payload
            if (!email) {
                throw new Error('Email is mandatory')
            }
            commit('addEmailSenderList', email)
        },
        removeEmailSenderList({commit}, payload) {
            const {email} = payload
            if (!email) {
                throw new Error('Email is mandatory')
            }
            commit('removeEmailSenderList', email)
        },
        setSelectedTemplates({commit}) {
            let payload = true
            this.state.printDocumentModule.templates.forEach((t:NoticeTemplate) => {
                if(t.selected){
                    payload = false
                }else if(t.isPack){
                    t.items?.forEach((p: PackNoticeTemplateInfo) => {
                        if(p.selected){
                            payload = false
                        }
                    })
                }
            })
            commit('checkSelectedTemplates', payload)
        },
        cleanState({ commit }) {
            commit('cleanState')
        },
        cleanConfirmationDialogState({ commit }) {
            commit("cleanPrintDocumentConfirmationDialogState")
        },
        hideConfirmationDialog({ commit }) {
            commit("setPrintDocumentConfirmationDialogState", {
                show: false,
            });
        },
        showPrintDocumentConfirmationDialog({ commit }, param) {
            commit("setPrintDocumentConfirmationDialogState", {
                message: i18n.global.t("printDocument.confirmationDialog.message"),
                show: true
            });
        },
    },
    mutations: {
        cleanState(state) {
            state.show = false
            delete state.reference

            state.templates = []
            state.emailSendList = []
            state.body =  ''
            state.showButtonsPreview = false
            state.showButtonValidate= false
            delete state.previewTemplate
            delete state.visualizedTemplate
            delete state.emailSendListOption

            state.fileDetailFileId = ''
            state.fileContent =''

        },
        toggleShow(state) {
            state.show = !state.show
        },
        showButton(state) {
            state.showButton = !state.showButton
        },
        setShow(state, payload) {
            state.show = payload;
        },
        setReference(state, payload) {
            state.reference = payload;
        },

        setTemplates(state, payload) {
            state.templates = payload
        },
        setVisualizedTemplate(state, payload) {
            state.visualizedTemplate = payload
        },
        setFileDetailFileId(state, payload) {
            state.fileDetailFileId = payload
        },
        setFileContent(state, payload) {
            state.fileContent = payload
        },

        setTemplateSelected(state, payload) {
            const {template, selected} = payload
            template.selected = selected
            if (template.isPack) {
                for (const item of template.items) {
                    item.selected = selected
                }
            }
        },
        setTemplateSelectedItem(state, payload) {
            const {template, selected, parentNotice} = payload
            template.selected = selected
            if (template.selected) {
                parentNotice.selected = true
            } else {
                for (const item of parentNotice.items) {
                    if (item.selected) {
                        parentNotice.selected = true
                        break
                    }
                    parentNotice.selected = false
                }
            }
        },
        setPreviewTemplate(state, payload) {
            state.previewTemplate = {
                arr: payload,
                page: 1
            }
        },
        setEmailSendListOption(state, payload) {
            state.emailSendListOption = payload
        },
        setShowSendTemplate(state, payload) {
            state.showSendTemplate = payload
        },
        setTemplateBody(state, payload) {
            state.body = payload
        },
        addEmailSenderList(state, payload) {
            if (state.emailSendList.indexOf(payload) === -1) {
                state.emailSendList.push(payload)
            }
        },
        removeEmailSenderList(state, payload) {
            state.emailSendList = state.emailSendList.filter(email => email !== payload)
        },
        checkSelectedTemplates(state, payload) {
            state.showButtonsPreview = payload
        },
        checkDownload(state, payload) {
            state.showButtonValidate = payload
        },
        setPrintDocumentConfirmationDialogState(state, payload) {
            state.printDocumentConfirmationDialogState = payload;
        },
        cleanPrintDocumentConfirmationDialogState(state) {
            delete state.printDocumentConfirmationDialogState;
        },
        setEmailSendList(state, payload) {
            state.emailSendList = payload;
        },
        setPrintDocumentModule(state, payload) {
            Object.assign(state, payload)
        }
    },
}

export default printDocumentModule;
