import i18n from "@/i18n";
import { FileContent, Metadata, QcHeader, ThirdType, UniformOdmName, receivedFile } from "@/types";
import { getParty } from "@/store/services/searchThird";
import { deepClone, doRef, groupByFn, headers, UON } from "@/utils";
import store from "@/store";
import { getCarProduct } from "@/store/services/financing/product";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import moment from "moment";
import Notify from 'quasar/src/plugins/Notify.js';;
import { getProcess, qualifyFile, receiveFile } from "@/store/services/task/actions/uploadDocumentService";
import { changeNbrReceeivedDoc } from "@/store/services/task/taskService";

export const processDocuments = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[]
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles
    } = payload;

    const processedDocuments: ProcessedDocument[] = [];
    const deleteManualDocs: ProcessedDocument[] = [];
    let nbrTargetEntityNull = 0;

    const targetEntityMap = new Map(
        supportingDocuments.filter((doc: { targetEntity: TargetEntity }) => doc.targetEntity !== null)
            .map((doc: { targetEntity: TargetEntity }) => {
                return [doc.targetEntity.resourceUid, doc.targetEntity];
            })
    );

    for (const [key, value] of targetEntityMap) {
        const entity = await getTargetEntity(value);
        value.reference = entity.reference ? entity.reference : entity.name
        value.information = entity.firstName ? `${entity.firstName} ${entity.familyName}` : entity.name ? `${entity.name}` : '-'
        value.roles = entity.roles
        value.expanded = false
    }

    const setStatus = (status: { resourceUid: string }) => {
        return status.resourceUid
    }

    for (const requiredDocument of requiredDocuments) {
        for (const supportingType of requiredDocument.supportingType) {
            const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.documentFamily.resourceUid === supportingType.supportingDocument.resourceUid);
            for (const supportingDocument of supDocumentsFiltered) {
                if (supportingDocument && supportingDocument.targetEntity) {
                    const processedDocument = new ProcessedDocument();
                    processedDocument.targetEntity = targetEntityMap.get(supportingDocument.targetEntity.resourceUid) as TargetEntity;
                    processedDocument.typeJustify = supportingDocument.documentFamily.resourceUid;
                    processedDocument.documentType = requiredDocument.resourceUid;
                    processedDocument.processResourceUid = gatheringProcess.resourceUid;
                    processedDocument.manual = supportingDocument.manual
                    const receivedFile = findReceivedFile(supportingDocument.resourceUid, requiredDocument.resourceUid, receivedFiles)
                    let pdStatus = ''
                    if (receivedFile) {
                        processedDocument.creationDate = moment(receivedFile.receptionDate).format("DD/MM/YYYY HH:mm");
                        const content = receivedFile.content[0];
                        if (content) {
                            pdStatus = receivedFile.status.resourceUid
                            processedDocument.status = setStatus(content.status);
                            processedDocument.comment = content.label;
                        }
                        processedDocument.supportingDocumentResourceUid = supportingDocument.resourceUid
                        processedDocument.receivedFileUid = receivedFile.resourceUid
                        processedDocument.document = {
                            originalFileName: content.label,
                            resourceUid: receivedFile.fileReference.resourceUid,
                            objectType: receivedFile.fileReference.objectType,
                            systemUid: receivedFile.fileReference.systemUid,
                            receivedFileUid: receivedFile.resourceUid
                        };
                    }
                    const processedDocumentAlreadyAdded = processedDocuments.find((x: any) => x.targetEntity.resourceUid === processedDocument.targetEntity.resourceUid
                        && x.typeJustify === processedDocument.typeJustify
                        && x.documentType === processedDocument.documentType);

                    const deleteManualDoc = deleteManualDocs.find((x: any) => x.receivedFileUid === processedDocument.receivedFileUid);

                    if (!processedDocumentAlreadyAdded) {
                        if (supportingDocument.manual) {
                            if (pdStatus !== 'CANCELED' && pdStatus !== '') {
                                processedDocuments.push(processedDocument)
                            } else if (!deleteManualDoc) {
                                deleteManualDocs.push(processedDocument)
                            }
                        } else {
                            processedDocuments.push(processedDocument);
                        }
                    }

                } else if (supportingDocument && !supportingDocument.targetEntity) {
                    nbrTargetEntityNull = nbrTargetEntityNull + 1;
                }
            }
        }
    }

    const processedDocumentsGrouped: Record<string, ProcessedDocument[]> | undefined = groupByFn(processedDocuments, (e: any) => {
        if (e.targetEntity.reference) return e.targetEntity.reference;
    });

    const documents: GatheringDocument[] = [];

    const entityTargets = Object.entries(processedDocumentsGrouped as Record<string, ProcessedDocument[]>)
        .map(([key, value], i) => {
            const entity = value.find(processedDocument => processedDocument.targetEntity.reference === key)?.targetEntity
            return {
                index: i++,
                reference: key,
                ...entity,
                documents: value
            }
        })

    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0
    documents.push({
        entityTargets,
        nbrReceivedItems: '',
        nbrValidatedItems: '',
        requiredDocuments: requiredDocuments.length - nbrTargetEntityNull - nbrDeleteManualDocs,
        status: gatheringProcess.status.resourceUid,
        assessmentContext: gatheringProcess.assessmentContext
    })

    for (const document of documents) {
        if (document.entityTargets) {
            for (const entityTarget of document.entityTargets) {
                for (const doc of entityTarget.documents) {
                    await handlePreExistentDocumentInLoading({
                        document: doc,
                        gatheringProcess: document
                    })
                }
            }
        }
    }

    /*for ( const document of documents ) {
        if (document.entityTargets) {
            for ( const entityTarget of document.entityTargets ) {
                for ( const doc of entityTarget.documents ) {
                    if(!doc.document?.originalFileName){
                        doc.status= "";
                        doc.document = {};
                    }             
                }
            }
        }
    }*/

    store.dispatch("taskModule/gatheringProcess", {
        request: {
            gatheringProcess: documents,
        },
    }).catch(e => console.error(e));

    store.dispatch("taskModule/setNbrReceivedDocuments");
}

export const handlePreExistentDocumentInLoading = async (attr: {
    document: ProcessedDocument,
    gatheringProcess: any
}) => {

    const { document, gatheringProcess } = attr;

    const offerReference = store.state.taskModule.offer?.reference as string || store.state.middleOfficeModule.offer?.reference as string;

    const targetEntityAttr = {
        documentType: document.documentType,
        targetEntityObjectType: document.targetEntity.objectType as string,
        targetEntityUID: document.targetEntity.resourceUid as string,
        associatedOffer: offerReference
    }

    const fileMetadata = await getFileMetadata(targetEntityAttr);

    const fileMetadataMap: Map<string, FilelMetadata> = new Map();
    for (const item of fileMetadata) {
        fileMetadataMap.set(item.documentType, item);
    }
    const metadataMap = new Map<string, string>();
    metadataMap.set("associatedOffer", offerReference);

    const metadata = fileMetadataMap.get(document.documentType);
    const system = i18n.global.t('task.supportingDocumentLabel.system');

    if (metadata && metadata.offerReference !== offerReference) {
        metadataMap.set("addedBy", `${offerReference};${system}`);
        metadataMap.forEach((value, key) => {
            addMetadata(metadata.fileId, { key, value });
        })
        document.addedBy = system;
        document.document.resourceUid = metadata.fileId;
        try {
            await store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
                request: document.document,
                row: document,
                callback: (response: any) => {
                    delete response.content
                    handleResponse({
                        response,
                        gatheringProcess,
                        document
                    });
                }
            });
        } catch (error) {
            console.error("Error in downloadFile:", error);
        }
    }
    else if (metadata && metadata.offerReference === offerReference) {
        const connectedUser = store.getters["authModule/getUserConnected"];
        document.addedBy = metadata.addedBy ? metadata.addedBy : `${connectedUser.firstName} ${connectedUser.lastName}`
        document.document.resourceUid = metadata.fileId;
        try {
            await store.dispatch('taskModule/uploadDocumentModule/downloadFile', {
                request: document.document,
                row: document,
                callback: (response: any) => {
                    delete response.content
                    handleResponse({
                        response,
                        gatheringProcess,
                        document
                    });
                }
            });
        } catch (error) {
            console.error("Error in downloadFile:", error);
        }
    }
    if (!document.receivedFileUid) {
        await receiveQualifySystemFile(document)
    }
}

async function receiveQualifySystemFile(document: any) {
    getProcess(document.processResourceUid).then((res: any) => {
        res.gatheringProcessItem.forEach((element: any) => {
            const file = {
                fileReference: {
                    objectType: "odm.filemngt.filedescriptor",
                    systemUid: "odm-filemngt",
                    resourceUid: document.document.resourceUid
                },
                creationLabel: document.document.originalFileName,
                qualification: [
                    {
                        documentType: {
                            resourceUid: document.documentType
                        },
                        label: document.document.originalFileName
                    }
                ],
                targetEntityId: document.targetEntity.resourceUid,
                systemUid: 'odm-supportingdocument'
            }
            receiveFile(document.processResourceUid, element.resourceUid, file).then(res => {
                document.receivedFileUid = res.resourceUid
                qualifyFile(document.processResourceUid, element.resourceUid, res.resourceUid, file)
            })
        });
    });
}

const handleResponse = (attr: { response: any, gatheringProcess: any, document: ProcessedDocument }) => {

    const { response, gatheringProcess, document } = attr;

    document.creationDate = document.creationDate ? document.creationDate : moment(new Date()).format("DD/MM/YYYY HH:mm");
    document.document = response || {};
    document.comment = response.originalFileName;

    if (!document.status) {
        document.status = i18n.global.t(
            `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
        );
    }

}

export const uploadDocument = (attr: { document: ProcessedDocument, files: FileList, gatheringProcess: any, metadataMap: Map<string, string>, documentReceivedFileID?: string }) => {
    const { document, files, gatheringProcess, metadataMap, documentReceivedFileID } = attr;
    document.document = files[0];
    if (document.document) {
        getBase64(document.document).then((base64) => {
            const fileContent: FileContent = {
                originalFileName: document.document.name,
                format: document.document.type,
                content: base64,
                relations: {
                    items: [
                        {
                            qualifier: {
                                rawValue: document.documentType,
                            },
                            objectUON: {
                                objectType: UON.ObjectType.fileManagement.fileContent,
                                systemUid: UON.SystemUid.odmFileManagement,
                            },
                        },
                    ],
                },
                metadata: getMetadata(metadataMap),
            };

            store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                request: fileContent,
                document: document,
                documentType: document.documentType,
                processid: document.processResourceUid,
                documentReceivedFileID: documentReceivedFileID,

                callback: (response: FileContent) =>
                    handleResponse({
                        response,
                        gatheringProcess,
                        document
                    }),
            }).then(() => changeNbrReceeivedDoc('INCREMENT')).catch(e => console.error(e));
        });
    } else {
        Notify.create({
            color: "negative",
            message: "Erreur!",
        });
    }
}

const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const resp = reader.result as string;
            resolve(resp.split("base64,")[1]);
        };
        reader.onerror = (error) => reject(error);
    });
}

const getMetadata = (metaDataMap: Map<string, string>) => {
    return Array.from(metaDataMap.entries()).map(([key, value]) => ({
        key, value
    }) as Metadata);
}

const getTargetEntity = async (targetEntity: TargetEntity) => {
    if (targetEntity.objectType?.toLowerCase().includes('person')) {
        return (await getParty(ThirdType.PERSON, targetEntity.resourceUid as string)).response;
    }
    if (targetEntity.objectType?.toLowerCase().includes('organization')) {
        return (await getParty(ThirdType.ORGANIZATION, targetEntity.resourceUid as string)).response;
    }
    return (await getCarProduct(targetEntity.resourceUid as string));
}

export const getFileMetadata = async (attr: {
    documentType: string,
    targetEntityObjectType: string,
    targetEntityUID: string,
    associatedOffer: string
}) => {
    const { documentType, targetEntityObjectType, targetEntityUID, associatedOffer } = attr;
    const qc_header: QcHeader = {
        qc: {
            queryId: "filemngt-metadata-target-entity",
            offset: 0,
            limit: -1,
            parameters: {
                documentType, targetEntityObjectType, targetEntityUID, associatedOffer
            }
        }
    };

    const { data } = (await api().get(settings.api_query_url, {
        headers: headers({ qc: JSON.stringify(qc_header.qc) })
    }));

    return data.map((el: { file_id: string, offer_reference: string, target_entity: string, document_type: string }) => ({
        fileId: el.file_id,
        offerReference: el.offer_reference,
        targetId: el.target_entity,
        documentType: el.document_type
    }));
}

export const addMetadata = (fileId: string, metadata: Metadata) => {
    const systemUid = UON.SystemUid.odmFileManagement
    const URI = `${settings.api_url}/${systemUid}/api/1/${systemUid}/filedescriptor/${fileId}/filemetadata/`;
    api().post(URI, metadata).catch(e => {
        console.error(e);
        throw e;
    });
}


export const deleteMetadataFile = async (fileId: string) => {
    const systemUid = UON.SystemUid.odmFileManagement
    const URI = `${settings.api_url}/${systemUid}/api/1/${systemUid}/filemetadata/${fileId}/`;
    return new Promise<receivedFile>(async (resolve, reject) => {
        try {
            const result = await api().delete(URI)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const deleteReceivedFile = (processUid: string, fileId: string) => {
    const systemUid = UON.SystemUid.odmSupportingDocument
    const URI = `${settings.api_url}/${systemUid}/api/1/${systemUid}/process/${processUid}/file/${fileId}/`;
    api().delete(URI).catch(e => {
        console.error(e);
        throw e;
    });
}

export const overwriteFile = async (file: any, processUid: string, fileId: string, docId: string) => {
    const systemUid = UON.SystemUid.odmSupportingDocument
    const baseUrl = `${settings.api_url}/${systemUid}/api/1/${systemUid}/`
    const body = file
    return new Promise<receivedFile>(async (resolve, reject) => {
        try {
            const result = await api().put(`${baseUrl}/process/` + processUid + `/document/` + docId + `/file/` + fileId + `/`, body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const findReceivedFile = (searchData: string, requiredDocument: string, receivedFiles: any[]) => {
    return receivedFiles.filter((dt: any) => dt.content.length > 0 && dt.content[0].documentType?.resourceUid === requiredDocument)
        .filter((ql: any) => ql.content[0].qualification.length > 0)
        .find((sp: any) => sp.content[0].qualification.find((sd: any) => sd.supportingDocument.resourceUid === searchData))
}

export type GatheringDocument = {
    entityTargets?: Array<{ reference: string, documents: ProcessedDocument[] }>
    nbrReceivedItems: string,
    nbrValidatedItems?: string,
    requiredDocuments?: number,
    status?: string,
    assessmentContext?: string
}

export type TargetEntity = {
    reference: string
    information: string
    type: string
    roles: never[],
    expanded: boolean
} & UniformOdmName

type UploadDocument = {
    originalFileName?: string,
    name?: string,
    type?: string,
    receivedFileUid?: string,
    metadata?: any,
} & UniformOdmName

type FilelMetadata = {
    fileId: string
    offerReference: string
    targetId: string
    documentType: string
    addedBy?: string
}

export class ProcessedDocument {
    reference: string;
    targetEntity: TargetEntity;
    typeJustify: string;
    documentType: string;
    creationDate: string;
    status: string;
    comment: string;
    processResourceUid: string;
    addedBy: string;
    document: UploadDocument
    supportingDocumentResourceUid: string;
    receivedFileUid: string;
    manual: string;

    constructor() {
        this.reference = doRef(2);
        this.targetEntity = {} as TargetEntity;
        this.typeJustify = '';
        this.documentType = '';
        this.creationDate = '';
        this.status = '';
        this.comment = '';
        this.processResourceUid = '';
        this.addedBy = '';
        this.document = {} as UploadDocument;
        this.supportingDocumentResourceUid = ''
        this.receivedFileUid = ''
        this.manual = ''
    }
}
