import i18n from "@/i18n";
import store from "@/store";
import {getAdditionalInformation} from "@/store/services/picklist";
import { getDocumentValueConfig } from "@/store/services/task/actions/order/completePreconditionsService";
import { checkFormatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import {FileContent} from "@/types";
import {UON, deepClone} from "@/utils";
import moment from "moment";
import Notify from 'quasar/src/plugins/Notify.js';;

export const processDocumentsOrder = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[],
    module:any
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    let nbrTargetEntityNull = 0;
    
    

    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0

    let listOfCheckpoints: any[] = []
    let nbrOfCheckPoint = 0;
    let nbrOfCheckPointValidated = 0;
    let nbrOfCheckPointNotValidated = 0;
    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
            
        }
       
    }
 

    for (const items of gatheringProcess.gatheringProcessItem[0].items) {
        const ListOfDocuments = []
        
        
        if (items.objectType.includes('fieldvalue') ) {
            if (items.businessData) {
                items.status.resourceUid = 'COMPLETED'
                items.businessData = {}
                nbrOfCheckPoint++
                nbrOfCheckPointValidated++
            } else {
                items.status.resourceUid = 'PENDING'
                items.businessData = null
                nbrOfCheckPoint++
                nbrOfCheckPointNotValidated++
            }
            listOfCheckpoints.push(items)
        }

        if( items.objectType.includes('businessrulevalue')){
            if (items.status.resourceUid === 'COMPLETED') {
                nbrOfCheckPoint++
                nbrOfCheckPointValidated++
            } else {
                nbrOfCheckPoint++
                nbrOfCheckPointNotValidated++
            }
          
            listOfCheckpoints.push(items)
        }
        if (items.objectType.includes('documentvalue')) {
            const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
            if (supDocumentsFiltered) {
                for (const document of requiredDocuments) {
                    let documentUpload = {};
                    let creationDate = "";
                    let status = "";
                    let comment = "";
                    const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                        (s: any) => s.content[0].documentType.resourceUid === document.resourceUid
                    );
                    if (doc.length > 0 && items.status && items.status.resourceUid === 'COMPLETED') {
                        documentUpload = {
                            format: "",
                            originalFileName: doc[doc.length - 1].content[0].label,
                            resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                            objectType: doc[doc.length - 1].fileReference.objectType,
                            systemUid: doc[doc.length - 1].fileReference.systemUid,
                        };
                        creationDate = doc[doc.length - 1].receptionDate;
                        status = doc[doc.length - 1].content[0].status.resourceUid;
                        comment = doc[doc.length - 1].content[0].label;


                    }
  
                    if(document.supportingDocumentUid===items.supportingDocument.resourceUid){
                        ListOfDocuments.push({
                            documentUpload: documentUpload,
                            creationDate: creationDate,
                            status: status,
                            comment: comment,
                            typeJustify: document.supportingType[0].supportingDocument.resourceUid,
                            documentType: document.resourceUid,
                            processResourceUid: gatheringProcess.resourceUid,
                        })
                    }
                    
                    
                    

                }
                nbrTargetEntityNull = nbrTargetEntityNull + 1
                items.documents = ListOfDocuments
                listOfCheckpoints.push(items)

            }
            for(const doc of items.documents){
                if(doc.documentUpload && doc.documentUpload.originalFileName ){
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                }else{
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }

        }
        const filter = {
            idCheck: items.resourceUid
        }
        const moreInfo = await getAdditionalInformation(filter)
        if (moreInfo.length > 0) {
            items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
        }

    }

    if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
        store.dispatch('orderModule/setPreConditionValid', true)
    }
    const documentValueItems = listOfCheckpoints.filter(item => item.objectType.includes('documentvalue'));
    const otherItems = listOfCheckpoints.filter(item => !item.objectType.includes('documentvalue'));
    listOfCheckpoints = [...documentValueItems, ...otherItems];
    const gatheringProcessOrder={
        resourceUid: gatheringProcess.resourceUid,
        nbrReceivedItems: nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint,
        nbrValidatedItems: nbrOfCheckPointValidated + '/' + nbrOfCheckPoint,
        requiredDocuments: requiredDocuments.length - nbrTargetEntityNull - nbrDeleteManualDocs,
        status: gatheringProcess.status.resourceUid,
        assessmentContext: gatheringProcess.contextType.rawValue,
        checkpoints: listOfCheckpoints
    }
    if(module==='taskModule'){
        const listOfGatheringProcessOrder = store.state.taskModule.gatheringProcessOrder || [];
        if(listOfGatheringProcessOrder.length > 0){
            const exists = listOfGatheringProcessOrder.some((item:any) => item.assessmentContext === gatheringProcessOrder.assessmentContext);
            if (!exists) {
                listOfGatheringProcessOrder.push(gatheringProcessOrder);
            }
        }else{
            listOfGatheringProcessOrder.push(gatheringProcessOrder);
        }
        await store.dispatch("taskModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    }
    if(module==='summaryPanelModule'){
        const listOfGatheringProcessOrder= store.state.summaryPanelModule.gatheringProcessOrder || [];
        await store.dispatch("summaryPanelModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    }

}

export const processDocumentsOffer =async (payload: {
    gatheringProcess: any 
    requiredDocuments: any,
    module:any

}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    let nbrTargetEntityNull = 0;
    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0

    const listOfCheckpoints: any[] = []
    let nbrOfCheckPoint = 0;
    let nbrOfCheckPointValidated = 0;
    let nbrOfCheckPointNotValidated = 0;
    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
            
        }
       
    }

    if (gatheringProcess.gatheringProcessItem[0] && gatheringProcess.gatheringProcessItem[0].items) {

        for (const items of gatheringProcess.gatheringProcessItem[0].items) {

            const ListOfDocuments = []
        
            if (items.objectType.includes('documentvalue')) {
                const supDocumentsFiltered = gatheringProcess.supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
                if (supDocumentsFiltered) {
                    for (const document of requiredDocuments) {
                        let documentUpload = {};
                        let creationDate = "";
                        let status = "";
                        let comment = "";
                        const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                            (s: any) => s.content[0].documentType.resourceUid === document.resourceUid
                        );
                        if (doc.length > 0 && items.status && items.status.resourceUid === 'COMPLETED') {
                            documentUpload = {
                                format: "",
                                originalFileName: doc[doc.length - 1].content[0].label,
                                resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                                objectType: doc[doc.length - 1].fileReference.objectType,
                                systemUid: doc[doc.length - 1].fileReference.systemUid,
                            };
                            creationDate = doc[doc.length - 1].receptionDate;
                            status = doc[doc.length - 1].content[0].status.resourceUid;
                            comment = doc[doc.length - 1].content[0].label;
    
    
                        }
      
                        if(document.supportingDocumentUid===items.supportingDocument.resourceUid){
                            ListOfDocuments.push({
                                documentUpload: documentUpload,
                                creationDate: creationDate,
                                status: status,
                                comment: comment,
                                typeJustify: document.supportingType[0].supportingDocument.resourceUid,
                                documentType: document.resourceUid,
                                processResourceUid: gatheringProcess.resourceUid,
                            })
                        }
                        
                         
    
                    }
                    nbrTargetEntityNull = nbrTargetEntityNull + 1
                    items.documents = ListOfDocuments
                    listOfCheckpoints.push(items)

 
                }
            
    
            }

            
            if (items.objectType.includes('fieldvalue')) {
                if (items.businessData) {
                    items.status.resourceUid = 'COMPLETED'
                    items.businessData = {}
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    items.status.resourceUid = 'PENDING'
                    items.businessData = null
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            listOfCheckpoints.push(items)
            }

            

            const filter = {
                idCheck: items.resourceUid
            }
            const moreInfo = await getAdditionalInformation(filter)
            if (moreInfo.length > 0) {
                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
            }
        }
    }

}
export async function UploadDocument(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.checkpoints[childId]
            if (documentItem) {
                documentItem.documents[index].documentUpload = files[0];
                if (documentItem.documents && documentItem.documents[index].documentUpload) {
                    getBase64(documentItem.documents[index].documentUpload).then((base64) => {
                        const fileContent: FileContent = {
                            originalFileName: documentItem.documents[index].documentUpload.name,
                            format: documentItem.documents[index].documentUpload.type,
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: {
                                            rawValue: documentItem.documents[index].documentType,
                                        },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        

                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: fileContent,
                            documentType: documentItem.documents[index].documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentResponse(response, resourceUid, childId,index),
                        });
                    });


                } else {
                    Notify.create({
                        color: "negative",
                        message: "Erreur!",
                    });
                }
            }

        }
    }
}

export async function UploadDocumentOffer(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.gatheringProcess) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.gatheringProcessItem[0].items[childId]
            if (documentItem) {
                documentItem.documents[index].documentUpload = files[0];
                if (documentItem.documents && documentItem.documents[index].documentUpload) {
                    getBase64(documentItem.documents[index].documentUpload).then((base64) => {
                        const fileContent: FileContent = {
                            originalFileName: documentItem.documents[index].documentUpload.name,
                            format: documentItem.documents[index].documentUpload.type,
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: {
                                            rawValue: documentItem.documents[index].documentType,
                                        },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        

                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: fileContent,
                            documentType: documentItem.documents[index].documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentOfferResponse(response, resourceUid, childId,index),
                        });
                    });


                } else {
                    Notify.create({
                        color: "negative",
                        message: "Erreur!",
                    });
                }
            }

        }
    }
}
export async function handleDocumentResponse(response: FileContent, resourceUid: any, childId: number,index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.checkpoints[childId].documents[index].documentUpload = response || {};
            process.checkpoints[childId].documents[index].creationDate = moment(new Date()).format("DD/MM/YYYY");
            process.checkpoints[childId].documents[index].status = 'QUALIFIED';
            process.checkpoints[childId].documents[index].comment = response.originalFileName;
            process.checkpoints[childId].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            process.checkpoints[childId].status.resourceUid = 'COMPLETED'
            const documentValue = deepClone(process.checkpoints[childId])
            delete documentValue.documents
            delete documentValue.whoModified
            const IsValid=process.checkpoints[childId].documents.filter((s: any) => 
            s.status !== 'QUALIFIED');
            if(IsValid.length===0){
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
            
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint

        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }


    }
}

export async function handleDocumentOfferResponse(response: FileContent, resourceUid: any, childId: number,index: number) {
    for (const process of store.state.taskModule.gatheringProcess) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.gatheringProcessItem[0].items[childId].documents[index].documentUpload = response || {};
            process.gatheringProcessItem[0].items[childId].documents[index].creationDate = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
            process.gatheringProcessItem[0].items[childId].documents[index].status = 'QUALIFIED';
            process.gatheringProcessItem[0].items[childId].documents[index].comment = response.originalFileName;
            process.gatheringProcessItem[0].items[childId].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            process.gatheringProcessItem[0].items[childId].status.resourceUid = 'COMPLETED'
            const documentValue = deepClone(process.gatheringProcessItem[0].items[childId])
            delete documentValue.documents
            delete documentValue.whoModified
            const IsValid=process.gatheringProcessItem[0].items[childId].documents.filter((s: any) => 
            s.status !== 'QUALIFIED');
            if(IsValid.length===0){
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
            
        }
        for (const items of process.gatheringProcessItem[0].items) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint

        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }


    }
}

export function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const resp = reader.result as string;
            resolve(resp.split("base64,")[1]);
        };
        reader.onerror = (error) => reject(error);
    });
}

export function removeItem(resourceUid: any, i: number,index: number) {
    store.dispatch('orderModule/setPreConditionValid', false)
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.checkpoints[i].documents[index].documentUpload = {}
            process.checkpoints[i].documents[index].status = "";
            if (process.checkpoints[i].status) {
                process.checkpoints[i].status.resourceUid = 'PENDING'
                process.checkpoints[i].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(process.checkpoints[i])
                delete documentValue.documents
                delete documentValue.whoModified

                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint


    }
}

export function removeOfferItem(resourceUid: any, i: number,index: number) {
    //store.dispatch('orderModule/setPreConditionValid', false)
    for (const process of store.state.taskModule.gatheringProcess) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.gatheringProcessItem[0].items[i].documents[index].documentUpload = {}
            process.gatheringProcessItem[0].items[i].documents[index].status = "";
            if (process.gatheringProcessItem[0].items[i].status) {
                process.gatheringProcessItem[0].items[i].status.resourceUid = 'PENDING'
                process.gatheringProcessItem[0].items[i].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(process.gatheringProcessItem[0].items[i])
                delete documentValue.documents
                delete documentValue.whoModified

                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
        }
        for (const items of process.gatheringProcessItem[0].items) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint


    }
}

export async function validateField(resourceUid: any, index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if( process.checkpoints[index].fieldValue !== undefined &&
                process.checkpoints[index].fieldValue  !== "" &&
                process.checkpoints[index].fieldType.resourceUid === "DATE" &&
                !isValidDate(process.checkpoints[index].fieldValue)){
                Notify.create({
                    message: i18n.global.t("main.date.invalidDate"),
                    color: "negative",
                });
                process.checkpoints[index].fieldValue ='';
                break;
            }
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'COMPLETED'
                process.checkpoints[index].completionDate = moment().format("DD/MM/YYYY");
                process.checkpoints[index].businessData = {}
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                fieldValue.completionDate = moment().format("YYYY-MM-DD");
                store.dispatch("taskModule/updateFieldValue", {
                    request: fieldValue,
                });

                store.dispatch("taskModule/saveCheckPointsValue", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }
    }
}

 function isValidDate(fieldValue: any): boolean {
    if (!checkFormatDate(fieldValue)) {
      return false;
    }
    const parts = fieldValue.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; 
    const year = parseInt(parts[2], 10);
    const currentYear =  new Date().getFullYear();
    if (month < 0 || month > 11 || year < currentYear - 10 || year > currentYear + 10) {
      return false;
    }
    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }

export function removeFieldValue(resourceUid: any, index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'PENDING'
                process.checkpoints[index].completionDate = null;
                process.checkpoints[index].businessData = null
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                store.dispatch("taskModule/updateFieldValue", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
    }

    store.dispatch('orderModule/setPreConditionValid', false)
}

function formatDate(date: any) {
    const [day, month, year] = date.split('/').map(Number);
    return new Date(year, month - 1, day).toISOString().split('T')[0];
}

export const processDocumentsOrderTab = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[],
    module:any
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0
    const listOfCheckpoints: any[] = []

    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
        }
        let documentUpload = {};
        let creationDate = "";
        let status = "";
        let comment = "";
        const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
            (s: any) => s.content[0].documentType.resourceUid === requiredDocument.resourceUid
        );
        if (doc.length > 0 ) {
            documentUpload = {
                format: "",
                originalFileName: doc[doc.length - 1].content[0].label,
                resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                objectType: doc[doc.length - 1].fileReference.objectType,
                systemUid: doc[doc.length - 1].fileReference.systemUid,
            };
            creationDate = doc[doc.length - 1].receptionDate;
            status = doc[doc.length - 1].content[0].status.resourceUid;
            comment = doc[doc.length - 1].content[0].label;


        }
        for (const items of gatheringProcess.gatheringProcessItem[0].items) {
            if(items.objectType.includes('documentvalue')){

            const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
                if (supDocumentsFiltered) {
                    if(requiredDocument.supportingDocumentUid===items.supportingDocument.resourceUid){
                        const documentObject ={
                            documentUpload: items.status && items.status.resourceUid === 'COMPLETED' ? documentUpload:{},
                            creationDate: creationDate,
                            status: items.status && items.status.resourceUid === 'COMPLETED' ?status:'PENDING',
                            comment: comment,
                            typeJustify: requiredDocument.supportingType[0].supportingDocument.resourceUid,
                            documentType: requiredDocument.resourceUid,
                            processResourceUid: gatheringProcess.resourceUid,
                        }
                        items.document=documentObject
                        const filter = {
                            idCheck: items.resourceUid
                        }
                        const moreInfo = await getAdditionalInformation(filter)
                            if (moreInfo.length > 0) {
                                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
                                items.creationDate =moreInfo[0]._when_created
                            }

                        const checkPoint = deepClone(items)
                        listOfCheckpoints.push(checkPoint)
                    }
                }
            }
        }  
    }
    for (const items of gatheringProcess.gatheringProcessItem[0].items) {
        if(items.objectType.includes('fieldvalue') || items.objectType.includes('businessrulevalue')){
            const filter = {
                idCheck: items.resourceUid
            }
            const moreInfo = await getAdditionalInformation(filter)
            if (moreInfo.length > 0) {
                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
                items.creationDate =moreInfo[0]._when_created
            }
            listOfCheckpoints.push(items)
        }

    } 

    

    const gatheringProcessOrder={
        resourceUid: gatheringProcess.resourceUid,
        status: gatheringProcess.status.resourceUid,
        assessmentContext: gatheringProcess.contextType.rawValue,
        checkpoints: listOfCheckpoints
    }
        const listOfGatheringProcessOrder= store.state.orderModule.gatheringProcessOrder || [];
        listOfGatheringProcessOrder.push(gatheringProcessOrder)
        await store.dispatch("orderModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    


}



export function validateBusinessField(resourceUid: any, index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'COMPLETED'
                process.checkpoints[index].completionDate = formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified

                store.dispatch("taskModule/updateCheckPointsStatus", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }

        for (const items of process.checkpoints) {
            if( items.objectType.includes('businessrulevalue')){
                if (items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }
    }
}

export function updateBusinessField(resourceUid: any, index: number, status: string) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = status
                process.checkpoints[index].completionDate = formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified

                store.dispatch("taskModule/updateCheckPointsStatus", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }

        for (const items of process.checkpoints) {
            if( items.objectType.includes('businessrulevalue')){
                if (items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }
    }
}